
/*
* ===================================================
*    Inline Popup Window 
* ===================================================
*/
.inlineWindow--container{
    position: fixed; 
    bottom: 0%;
    z-index: $zindex-fixed;
    .head{
        @include font-size(18);
        background-color: rgba($black, 0.9);
        color: $white; 

        .leftPanel{
            cursor: pointer;
        }

        .svg-icon{
            cursor: pointer;
            min-width: 15%;
        }

        .closed{
            min-width: 10%;
        }
    }

    .head:hover{
        background-color: $black;
    }

    .chatlist--no-chats{
        opacity: 0.4;
        position: relative;
        top: 40%;
        transform: translate(0%,-50%);
    }

    .inlineWindow--body{
        background-color: $white;
        color: $black;
        overflow-y: hidden;
        .searchbarContainer{

            .searchbar{
                background-color: $gray-100;
                
                .form-control{
                    background-color: $gray-100;
                    border: 0px;
                }

                .input-group-text{
                    border: 0px;
                }
            }
        }
    }

}


