.scrollControl--container {
  @extend .position-absolute;

  cursor: pointer;
  height: 40px;
  width: 42px;
  right: 50px;
  bottom: 50px;
  background-color: $primary;
  color: $white;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}
