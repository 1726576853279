/* ==========================================================================
Blog overview
========================================================================== */
.blog__overview {
  .card {
    @extend
      .border-0,
      .h-100;

    background-color: transparent;
    border-radius: 0;
    margin-bottom: 50px
  }

  .card-img-top {
    border-radius: 0;
  }

  .card-body {
    @extend
      .p-0;
  }

  .card-date {
    @include font-size(24);

    color: rgba($black, 0.5);
    font-family: $font-family-serif;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 10px;
    text-transform: uppercase;
  }

  .card-title {
    @include font-size(32);

    color: $black;
    font-family: $font-family-serif;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .btn {
    @extend
      .p-0;

    &:focus,
    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}

@include media-breakpoint-down(sm) {
  .blog__overview {
    .card-img-top {
      height: 200px;
      object-fit: cover;
    }
  }
}

@include media-breakpoint-up(lg) {
  .blog__overview {
    .card {
      margin-bottom: 100px;
    }

    .card-date {
      margin-bottom: 20px;
      margin-top: 20px;
    }

    .card-text {
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .blog__overview {
    .container {
      padding-left: 25px;
      padding-right: 25px;
    }

    .row {
      margin-left: -25px;
      margin-right: -25px;
    }

    div[class^="col"] {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}

/* ==========================================================================
Blog row
========================================================================== */
.blog__row {
  &.blog__row-top {
    margin-bottom: 50px;
    margin-top: 50px;
  }

  &.blog__row-bottom {
    margin-bottom: 50px;
  }

  &.blog__row-top,
  &.blog__row-bottom {
    .card {
      margin-bottom: 0;
    }
  }
}

@include media-breakpoint-only(md) {
  .blog__row {
    &.blog__row-top,
    &.blog__row-bottom {
      .card-img-top {
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@include media-breakpoint-only(lg) {
  .blog__row {
    &.blog__row-top,
    &.blog__row-bottom {
      .card-img-top {
        height: 100%;
        object-fit: cover;
      }
    }
  }
}


@include media-breakpoint-up(lg) {
  .blog__row {
    &.blog__row-top {
      margin-bottom: 100px;
      margin-top: 100px;
    }

    &.blog__row-bottom {
      margin-bottom: 100px;
    }
  }
}

/* ==========================================================================
Blog pagination
========================================================================== */
.blog__pagination {
  margin-bottom: 50px;
}

@include media-breakpoint-up(lg) {
  .blog__pagination {
    margin-bottom: 100px;
  }
}
