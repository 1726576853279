/* ==========================================================================
Blog columns
========================================================================== */
.blog__columns {
  margin-bottom: 30px;

  img {
    height: 300px;
    margin-bottom: 30px;
    object-fit: cover;
    width: 100%;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

@include media-breakpoint-up(lg) {
  .blog__columns {
    margin-bottom: 70px;

    img {
      height: 100%;
      margin-bottom: 0;
      object-fit: cover;
    }
  }
}

@include media-breakpoint-up(xl) {
  .blog__columns {
    .container {
      padding-left: 25px;
      padding-right: 25px;
    }

    .row {
      margin-left: -25px;
      margin-right: -25px;
    }

    div[class^="col"] {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}
