// this is the place for your components / CSS
body {
  font-size: 1.1rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @extend .text-uppercase;
}

.display-1 {
  letter-spacing: -0.175rem;
}

.btn {
  letter-spacing: 0.025rem;
}

.card {
  .wishlist-icon {
    cursor: pointer;
    transition: opacity 250ms 250ms ease;
  }
  .wishlist-icon:not(.active) {
    visibility: hidden;
    opacity: 0;
  }
  &:hover .wishlist-icon {
    visibility: visible;
    opacity: 1;
  }
}

.swiper-container {
  width: 100%;
  height: 20vh;
}

.page:not(.navbar-transparant) {
  padding-top: 65px;
}

.megamenu {
  box-shadow: none;
  .megamenu-list {
    font-size: $font-size-base;
    line-height: 1.1;
  }
}

.navbar .nav-link {
  font-size: $font-size-base * 1.1;
}

.avatar-initials {
  @extend .bg-dark, .h4, .mb-0, .text-light;
  line-height: 1.5;
}

.avatar-initials.avatar-lg {
  @extend .h1;
  line-height: 1.9;
}

.text-uppercase {
  text-transform: uppercase;
  letter-spacing: -1.2px;
}

.dropdown-toggle.hide-icon::after {
  content: none;
}

.megamenu-list-link.active {
  background-color: initial; //rgba($primary, .05);
  font-weight: bold;
}

.navbar .dropdown-toggle::after {
  position: relative;
}

.search-results.dropdown-menu {
  &::before {
    content: none;
  }
}

/* VIDEO */
.player-wrapper {
  position: relative;
  padding-top: 56.25%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.hero .react-player video {
  object-fit: cover;
}

/* CHAT */
.chat .dropdown-toggle::after {
  content: none;
}

/* TAGGED */
.tagged {
  width: 100%;
  position: relative;
  overflow: hidden;
  text-align: center;
  img {
    position: relative;
    width: 100%;
    height: auto;
  }
  .tag {
    position: absolute;
    display: block;
    .dropdown-toggle {
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.35);
      opacity: 0.75;
      &:hover {
        opacity: 1;
      }
    }
    .card {
      min-width: 240px;
    }
  }
}

/* PAGINATION */
.pagination li {
  @extend .page-item;
  a {
    @extend .page-link;
  }
  &.currentPage a {
    z-index: 3;
    color: $pagination-active-color;
    background-color: $pagination-active-bg;
    border-color: $pagination-active-border-color;
  }
}

.react-images__view--isModal {
  @include media-breakpoint-up('sm') {
    img {
      object-fit: scale-down;
      max-width: 100vw !important;
      max-height: 100vh !important;
      height: auto !important;
    }
  }
}

/* GALLERY */
.gallery {
  .img-container {
    align-items: start;
    height: 40vw;
    width: 40vw;
    border: 1px solid black;
    display: grid;

    img {
      display: block;
      height: auto;
      margin: 0 auto;
      max-height: 40vw;
      max-width: 40vw;
    }

    .tag-container {
      position: relative;
    }

    .tag-container-tag {
      color: limegreen;
      font-family: sans-serif;
      font-weight: bold;
      left: 50%;
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@-webkit-keyframes fadeInUpSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 25%, 0);
    transform: translate3d(0, 25%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUpSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 25%, 0);
    transform: translate3d(0, 25%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInUpSmall {
  -webkit-animation-name: fadeInUpSmall;
  animation-name: fadeInUpSmall;
}

@-webkit-keyframes fadeInRightSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(25%, 0, 0);
    transform: translate3d(25%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRightSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(25%, 0, 0);
    transform: translate3d(25%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInRightSmall {
  -webkit-animation-name: fadeInRightSmall;
  animation-name: fadeInRightSmall;
}

@-webkit-keyframes kenBurns {
  from {
    -webkit-transform: scale(1.08) rotate(-0.02deg);
    transform: scale(1.08) rotate(-0.02deg);
    opacity: 0.2;
  }

  to {
    opacity: 1;
    // -webkit-transform: translate3d(0, 0, 0);
    // transform: translate3d(0, 0, 0);
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@keyframes kenBurns {
  from {
    -webkit-transform: scale(1.08) rotate(-0.02deg);
    transform: scale(1.08) rotate(-0.02deg);
    opacity: 0.2;
  }

  to {
    opacity: 1;
    // -webkit-transform: translate3d(0, 0, 0);
    // transform: translate3d(0, 0, 0);
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}

.animate__kenBurns {
  -webkit-animation-name: kenBurns;
  animation-name: kenBurns;
  transition: transform 0.5s cubic-bezier(0.3, 0, 0.7, 1), opacity 1s ease;
}

/* ==========================================================================
Navbar
========================================================================== */
.navbar {
  .nav-link {
    @extend .px-lg-3;

    cursor: pointer;
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;

    &:focus,
    &:hover {
      background-color: $gray-100;
      color: $primary !important;
    }
  }

  form {
    @extend .mx-lg-2, .mx-xl-5;

    width: 100%;
  }

  padding: 0.5rem;
}

@include media-breakpoint-down(md) {
  .navbar {
    .navbar-nav {
      border-top: 1px solid $gray-200;
    }

    .nav-link {
      border-bottom: 1px solid $gray-200;
    }
  }
}

@include media-breakpoint-up(lg) {
  .navbar {
    .megamenu {
      margin-top: -30px !important;
    }
  }
}

@include media-breakpoint-up(xl) {
  .navbar {
    form {
      max-width: 320px;
    }
  }
}

/* ==========================================================================
Navbar
========================================================================== */
.nav-avatar {
  @extend .ml-lg-2, .mt-4, .mt-lg-0, .px-3, .px-lg-0, .py-2, .py-lg-0;

  .dropdown-toggle {
    @extend .w-100;

    &::after {
      @extend .d-lg-none, .m-0, .mr-3;

      height: auto;
      position: absolute;
      right: 0;
      top: 20px;
      width: auto;
    }
  }

  .dropdown-menu {
    @extend .mt-2, .mt-lg-0;
  }

  .nav-item {
    @extend .mb-0, .text-left;
  }
}

@include media-breakpoint-down(md) {
  .nav-avatar {
    background-color: $gray-200;
    border-radius: 5px;
  }
}

/* ==========================================================================
Navbar brand
========================================================================== */
.navbar-brand {
  @extend .py-0;
}
