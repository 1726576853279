/* ==========================================================================
Blog
========================================================================== */
@import 'blog/blog-columns';
@import 'blog/blog-cta';
@import 'blog/blog-detail';
@import 'blog/blog-overview';
@import 'blog/blog-tags';

/* ==========================================================================
Temporary
========================================================================== */
.hero__placeholder {
  background-color: #F8F9FA;
  height: 475px;
  line-height: 475px;
  margin-top: 56px;
  text-align: center;
}

@include media-breakpoint-up(lg) {
  .hero__placeholder {
    margin-top: 66px;
  }
}
