
/*
* ===================================================
*     Professionals Page 
* ===================================================
*/
.professional-cover{
    max-height: 250px;
    >img{
        width: 100%;
        object-fit: cover;
        object-position: center; 
    }
}
.professional-details{
    position: relative;
    top: -100px;
}

.project-card--container{
    cursor: pointer;
}

.project-description{
    @include font-size(12);
    line-height: 50%;
}

.project-thumbnail{
    height: 400px;
    width: auto;
    object-fit: cover;
}