/*
* ===================================================
*    Container for events overview page 
* ===================================================
*/
.events-overview--container {
  position: relative;
  top: -20vh;
  margin-bottom: -15vh;
}

/*
* ===================================================
*   Body card (Events) 
* ===================================================
*/
.events__body-card-title {
  @extend .h5;
  @include media-breakpoint-down(sm) {
    height: 65px;
    display: flex;
  }
  @media (max-width: 429px) {
    margin-bottom: 3vh;
    padding-left: 6vw;
  }
}

.events__body-card-date {
  margin-right: 0px;
  margin-left: 0px;
  max-width: 65px;
  max-height: 65px;
  background-color: $primary;
  color: $white;

  @include media-breakpoint-up(xl) {
    width: 65px;
    height: 65px;
  }

  @include media-breakpoint-down(sm) {
    margin-right: auto;
    margin-left: auto;
    height: 55px;
    width: 55px;
  }

  &__secondary {
    height: 65px;
    width: 65px;
    background-color: $white;
    color: $black;
  }

  &__day {
    @extend .h4;
    @include media-breakpoint-down(sm) {
      font-size: 1.8rem;
    }
  }
  &__month {
    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }
  }
}

.events__body-tags-container {
  @include media-breakpoint-down(sm) {
    position: relative;
    right: 65px;
    width: calc(100% + 65px);
  }

  @include media-breakpoint-down(xs) {
    right: 55px;
    width: calc(100% + 55px);
  }
}

.events__body-card-tag {
  background-color: rgba(112, 112, 112, 0.05);
  @include media-breakpoint-down(sm) {
    font-size: 1.1rem;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

/*
* ===================================================
*   Body card (Recaps/Swiper) 
* ===================================================
*/

.events__body-swiper-navigation--container {
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}
.events__body-swiper-navigation {
  .swiper-button-prev,
  .swiper-button-next {
    top: 0 !important;
    position: initial !important;
    height: 40px;
    width: 40px;
    color: $primary !important;
    background-color: $gray-200;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 16px;
    font-weight: $font-weight-bolder;
    zoom: 120%;
  }
}

.events__body-swiper-card {
  .card-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.events__body-swiper-card {
  .card-img-top--container {
    position: relative;

    .card-img-top--date {
      position: absolute;
      bottom: 15px;
      left: 15px;
      object-fit: cover;
    }

    .card-img-top--img {
      width: 100%;
      height: 35vh;
      object-fit: cover;
    }
  }

  p {
    @include font-size(16);
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.event__body-card-text-container {
  min-height: 7.5vh;
  @media (max-width: 340px) {
    height: 40% !important;
  }
}

.event__body-button-container {
  @extend .h-50;
}
