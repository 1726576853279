.register-professsional-container {
  .form {
    @include media-breakpoint-down(md) {
      align-items: flex-start !important;

      > div {
        padding: 0.5rem !important;
      }
    }
  }

  .testimonials {
    @include media-breakpoint-down(md) {
      padding: 1rem !important;
    }
  }
}
