@import './themes/directory/theme.scss';

.filepond--root {
  height: 150px !important;
  width: 150px !important;
}

.page-wrapper {
  overflow: hidden;
}
