.stepwizard-step {
  .p,
  p {
    margin-top: 0px;
    color: black;
    margin-bottom: 0px;
    width: 100%;
    font-size: 1.375rem;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 30px;
    font-family: $font-family-serif;
  }

  .small,
  small {
    font-weight: bold;
  }
}

.stepper-step-icon {
  margin-right: 10px;
}

.stepper-row-item{
  cursor: pointer;
}


.stepwizard-row {
  display: flex;
  flex-wrap: wrap;
}
.stepwizard {
  width: 100%;
  position: relative;
  margin-bottom: 60px;
}
.stepwizard-step button[disabled] {
  /*opacity: 1 !important;
    filter: alpha(opacity=100) !important;*/
}
.stepwizard .btn.disabled,
.stepwizard .btn[disabled],
.stepwizard fieldset[disabled] .btn {
  opacity: 1 !important;
  border-color: $primary;
  background-color: white;
}
.stepwizard-row:before {
  top: 38px;
  bottom: 0;
  position: absolute;
  content: ' ';
  width: 100%;
  height: 1px;
  background-color: $primary;
  z-index: 0;

  @include media-breakpoint-down(sm) {
    height: 100%;
    width: 1px;
    right: 22px;
    top: 0px;
  }
}
.stepwizard-step {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  position: relative;

  @include media-breakpoint-down(sm) {
    justify-content: center;
    flex-direction: row;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
.btn-circle {
  width: 15px;
  height: 14px;
  text-align: center;
  padding: 3px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}
