/*
* ===================================================
*  Chatbox
* ===================================================
*/

.chatbox--header{
    height: fit-content;
    background-color: $white;
}

.chatbox--messagesList{
    height: 60%;
    overflow-y: auto;
    scroll-behavior: smooth !important;
}

.chatbox--controls{
    background-color: $white;
    textarea{
        @include font-size(15);
        border-style: none; 
        border-color: Transparent; 
        overflow: auto; 
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;    
        resize: none;
    }

    .chatbox--send-button{
        @include font-size(15);
        color: $gold;
        border-color: $gold;
        background-color: $white;
        &:hover{
            color: $white;
            background-color: $gold;
        }
    }


    i{
        opacity: 0.5;
        cursor: pointer;
        &:hover{
            opacity: 1;
        }
    }


}

/*
* ===================================================
*  ChatItem
* ===================================================
*/

.chatitem--container{
    &:hover{
        cursor: pointer;
        background-color: $gray-100;
    }
    
}

/*
* ===================================================
*  Message
* ===================================================
*/

.message--container{
    border-radius: 15px;
    p{
        @include font-size(15)
    }
}

.message--sent{
    background-color: $gold;
    float: right; 
    border-top-right-radius: 0px;
    p{
        color: $white;
    }

    small, i{
        color: $white;
    }
}

.message--received{
    background-color: $gray-200;
    float: left; 
    border-top-left-radius: 0px;

    p{
        color: $black;
    }

    small, i{
        color: $gold;
    }
}


/*
* ===================================================
*  Messages List
* ===================================================
*/

.messsageslist--container{
    height: 90%;
    padding-top: 5%;
}





