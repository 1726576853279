/* ==========================================================================
Blog call to action
========================================================================== */
.blog__cta {
  background-color: $custom-gray-1;
  padding-bottom: 50px;
  padding-top: 50px;
  position: relative;

  h2 {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 30px;
  }

  img {
    margin-bottom: 30px;
  }
}

@include media-breakpoint-only(xs) {
  .blog__cta {
    .btn {
      width: 100%;
    }

    .btn + .btn {
      margin-top: 20px;
    }
  }
}

@include media-breakpoint-only(sm) {
  .blog__cta {
    .btn + .btn {
      margin-left: 20px;
    }
  }
}

@include media-breakpoint-up(md) {
  .blog__cta {
    .btn {
      width: 100%;
    }

    .btn + .btn {
      margin-top: 20px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .blog__cta {
    padding-bottom: 100px;
    padding-top: 100px;

    h2,
    p {
      margin-bottom: 40px;
    }

    img {
      margin-bottom: 0;
    }
  }
}

@include media-breakpoint-up(xl) {
  .blog__cta {
    .btn {
      width: auto;
    }

    .btn + .btn {
      margin-left: 25px;
      margin-top: 0;
    }

    .container {
      padding-left: 25px;
      padding-right: 25px;
    }

    .row {
      margin-left: -25px;
      margin-right: -25px;
    }

    div[class^='col'] {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}
