.notification-badge {
  position: absolute !important;
  top: 0 !important;
  right: 15px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-notification .notification-badge {
  position: relative !important;
  margin-left: 20px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
}
