// this is the place for your variable overrides, paste variables from custom/_variables.scss here and set your values

// primary colour is set to false by default to enable multiple colour variants stylesheet creation,
// change to your brand colour if needed
$primary: #c08d2d;
$secondary: #242429;
$light: white;
$dark: #161615;
$custom-gray-1: #f8f9fa;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1400px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1340px
) !default;

$font-family-serif:           'D-DIN Condensed', sans-serif !default;
$font-family-sans-serif:      'D-DIN', sans-serif !default;
$font-family-heading:         $font-family-serif;
$font-family-base:            $font-family-sans-serif !default;

$font-size-base:              1.25rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base * .875 !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.5 !default;

$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;


$headings-line-height:        .875 !default;

$btn-block-spacing-y:         0;
$btn-font-size:               1rem !default;

$btn-border-radius:           0 !default;
$btn-border-radius-lg:        0 !default;
$btn-border-radius-sm:        0 !default;
