@font-face {
  font-family: 'D-DIN Condensed';
  src: url('../../../../fonts/D-DINCondensed-Bold.woff2') format('woff2'),
    url('../../../../fonts/D-DINCondensed-Bold.woff') format('woff'),
    url('../../../../fonts/D-DINCondensed-Bold.ttf') format('truetype'),
    url('../../../../fonts/D-DINCondensed-Bold.svg#D-DINCondensed-Bold')
      format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'D-DIN Condensed';
  src: url('../../../../fonts/D-DINCondensed.woff2') format('woff2'),
    url('../../../../fonts/D-DINCondensed.woff') format('woff'),
    url('../../../../fonts/D-DINCondensed.ttf') format('truetype'),
    url('../../../../fonts/D-DINCondensed.svg#D-DINCondensed') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'D-DIN';
  src: url('../../../../fonts/D-DIN.woff2') format('woff2'),
    url('../../../../fonts/D-DIN.woff') format('woff'),
    url('../../../../fonts/D-DIN.ttf') format('truetype'),
    url('../../../../fonts/D-DIN.svg#D-DIN') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'D-DIN';
  src: url('../../../../fonts/D-DIN-Bold.woff2') format('woff2'),
    url('../../../../fonts/D-DIN-Bold.woff') format('woff'),
    url('../../../../fonts/D-DIN-Bold.ttf') format('truetype'),
    url('../../../../fonts/D-DIN-Bold.svg#D-DIN-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'D-DIN';
  src: url('../../../../fonts/D-DIN-Italic.woff2') format('woff2'),
    url('../../../../fonts/D-DIN-Italic.woff') format('woff'),
    url('../../../../fonts/D-DIN-Italic.ttf') format('truetype'),
    url('../../../../fonts/D-DIN-Italic.svg#D-DIN-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
