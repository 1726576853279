/* ==========================================================================
Blog tags
========================================================================== */
.blog__tags {
  margin-bottom: 50px;

  .tags {
    @extend
      .d-flex;
  }

  .tag {
    @include font-size(14);

    background-color: rgba($black, 0.05);
    font-family: $font-family-sans-serif;
    font-weight: 700;
    line-height: 20px;
    margin-right: 10px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
}
