/* ==========================================================================
Blog detail
========================================================================== */
.blog__detail {
  margin-top: 50px;
}

@include media-breakpoint-up(lg) {
  .blog__detail {
    margin-top: 100px;
  }
}

/* ==========================================================================
Blog inner
========================================================================== */
.blog__inner {
  margin-bottom: 20px;
}

@include media-breakpoint-up(lg) {
  .blog__inner {
    margin-bottom: 70px;
  }
}

/* ==========================================================================
Blog title
========================================================================== */
.blog__title {
  margin-bottom: 20px;

  h1 {
    margin-bottom: 0;
  }
}

/* ==========================================================================
Blog date
========================================================================== */
.blog__date{
  margin-bottom: 10px;
  margin-top: 10px;  
  margin-left: 0px;
  p{
    @include font-size(24);
    color: rgba($black, 0.5);
    font-family: $font-family-serif;
    font-weight: 700;
    text-transform: uppercase;
  }

}

/* ==========================================================================
Blog content
========================================================================== */
.blog__content {
  p {
    margin-bottom: 30px;
  }
}

/* ==========================================================================
Blog image
========================================================================== */
.blog__image {
  margin-bottom: 50px;
  margin-top: 50px;
}

/* ==========================================================================
Blog quote
========================================================================== */
.blog__quote {
  margin-bottom: 50px;
  margin-top: 50px;

  blockquote {
    @include font-size(32);

    font-family: $font-family-serif;
    font-weight: 700;
    line-height: 0.8;
    margin-bottom: 0;
    text-align: center;
    text-transform: uppercase;
  }
}

@include media-breakpoint-up(lg) {
  .blog__quote {
    margin-bottom: 100px;
    margin-top: 100px;
  }
}

/* ==========================================================================
Blog related projects
========================================================================== */
.blog__related {
  background-color: $custom-gray-1;
  padding-bottom: 50px;
}

@include media-breakpoint-up(lg) {
  .blog__related {
    padding-bottom: 100px;
  }
}

@include media-breakpoint-up(xl) {
  .blog__related {
    .container {
      padding-left: 25px;
      padding-right: 25px;
    }

    .row {
      margin-left: -25px;
      margin-right: -25px;
    }

    div[class^="col"] {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}
